@import './dimensions.module.scss';

@function toRem($value: number) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

@mixin respond($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $mobileBreakpoint) {
      @content;
    }
  }
}

@mixin custom($px) {
  @media screen and (max-width: #{$px}px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobileBreakpoint) {
    @content;
  }
}
