@import '../../styles/functions.module.scss';
@import '../../styles/palette.module.scss';

@include respond(mobile) {
  .section {
    .container {
      .image {
        width: toRem(324);
        height: toRem(170);
      }
      .title {
        margin-top: toRem(35);
      }
      .description {
        font-size: toRem(18);
        margin-bottom: toRem(30);
      }
    }
  }
}

.image {
  max-width: toRem(550);
}

.section {
  padding-top: toRem(130);
  padding-bottom: toRem(130);
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
.title {
  margin-top: toRem(70);
  font-size: toRem(53);
  font-weight: 900;
  color: $grey900;
}
.description {
  margin-bottom: toRem(45);
  font-size: toRem(24);
  color: $grey500;
  font-weight: normal;
  text-align: center;
}
.button {
  width: toRem(300);
}
