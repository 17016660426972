$white: #fff;
$black: #000;

$blue50: #eff4fd;
$blue100: #fbfbff;
$blue200: #f0f5ff;
$blue300: #5e9efc;
$blue300-04: rgba(0, 104, 255, 0.04);
$blue400: #2b67ff;
$blue500: #0068ff;
$blue700: #0951b9;
$blue900: #001847;

$orange100: rgba(255, 80, 0, 0.05);
$orange200: #fff3ed;
$orange250: #fff6f2;
$orange300: #f89c73;
$orange500: #ff5001;
$orange500-04: rgba(255, 81, 2, 0.04);
$orange700: #db4400;

$grey50: #f6f8fa;
$grey100: #d7dcea;
$grey200: #f5f6f8;
$grey300: #d7dcea;
$grey400: #c1c3d2;
$grey500: #7c8196;
$grey600: #505b6e;
$grey700: #4a4e80;
$grey900: #262f4d;

$green500: #01ff59;

$red200: #fff5f4;
$red500: #ff483d;

:export {
  white: $white;
  black: $black;

  blue300: $blue300;
  blue500: $blue500;
  blue700: $blue700;
  blue900: $blue900;

  orange100: $orange100;
  orange300: $orange300;
  orange500: $orange500;
  orange700: $orange700;

  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey700: $grey700;
  grey900: $grey900;

  red200: $red200;
  red500: $red500;
}
